@use "./mixins" as *;

@media (width < 30em) {
  .titleContainer > div {
    @include flexProp(none, none, column, none);
  }
}

@media (width < 800px) {
  .titleContainer {
    @include flexProp(none, none, column, 1.5rem);

    .searchInput {
      width: 100%;
      padding: 1rem;
    }
  }

  .enterBtn {
    bottom: 2rem;
    right: 2rem;
  }
}

@media (width > 390px) {
  .note-container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}
