html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.466);
}

textarea {
  outline: none;
}

@font-face {
  font-family: Gilroy-light;
  src: url(/font/Gilroy-Light.otf);
}
@font-face {
  font-family: gilroy-bold;
  src: url(/font/Gilroy-ExtraBold.otf);
}
.listItemContainer-button {
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  position: relative;
}
.listItemContainer-button .listItemMenu {
  cursor: pointer;
}
.listItemContainer-button .sidebarMenu {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  position: absolute;
  right: 0;
  visibility: hidden;
  top: 0;
  transition: 0.3s ease;
  opacity: 0;
  display: grid;
  place-items: center;
  transform: translateY(-0.9rem);
  width: 9em;
}
.listItemContainer-button .sidebarMenu button {
  padding: 1em 3em;
  width: 100%;
  cursor: pointer;
  font-family: Gilroy-light;
  border: none;
  background-color: transparent;
}
.listItemContainer-button .sidebarMenu button:hover {
  background-color: #f3f3f3;
  color: #ff773d;
}
.listItemContainer-button > * {
  background-color: transparent;
  border: none;
}
.listItemContainer-button > *:focus + ul.sidebarMenu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.enterBtn {
  width: 4rem;
  height: 4rem;
  background-color: #ff773d;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  z-index: 9999;
}
.enterBtn i {
  font-size: 1.5rem;
  color: #ffffff;
  transition: 0.3s ease;
}
.enterBtn:hover {
  filter: brightness(1.1);
}
.enterBtn:hover i {
  font-size: 2rem;
}

.createBtn {
  border: none;
  color: #dbdbdb;
  padding: 1rem 1.5rem;
  border-radius: 30px;
  font-family: gilroy-bold;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 1.5rem;
  float: right;
  border: 1px solid #dbdbdb;
  transition: all 0.3s ease;
}
.createBtn:disabled:hover {
  box-shadow: none;
  transform: translateY(0);
}
.createBtn:hover {
  box-shadow: 0px 4px 0px 0px rgba(51, 51, 51, 0.2705882353);
  transform: translateY(-4px);
}
.createBtn:focus {
  transform: translateY(0);
  box-shadow: 0px 0px 0px 0px rgba(51, 51, 51, 0.2705882353);
}

.closeBtn {
  border: none;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.514);
  font-size: 1rem;
  font-family: Gilroy-light;
  margin-bottom: 1rem;
  cursor: pointer;
  float: right;
}

@media (width < 30em) {
  .titleContainer > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: none;
    align-items: none;
    flex-direction: column;
    gap: none;
  }
}
@media (width < 800px) {
  .titleContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: none;
    align-items: none;
    flex-direction: column;
    gap: 1.5rem;
  }
  .titleContainer .searchInput {
    width: 100%;
    padding: 1rem;
  }
  .enterBtn {
    bottom: 2rem;
    right: 2rem;
  }
}
@media (width > 390px) {
  .note-container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}
.createModal {
  background-color: #ff773d;
  border: none;
  padding: max(2rem, 2vw);
  margin: auto;
  word-break: break-all;
  color: white;
  position: relative;
}
.createModal::-webkit-backdrop {
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
}
.createModal::backdrop {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.createModal ::selection {
  background-color: #dbdbdb;
  color: #ff773d;
}
.createModal .modalTitle, .createModal .modalBody {
  font-size: clamp(1.3rem, 4vw, 2.5rem);
  background-color: transparent;
  padding-bottom: 0.5rem;
  border: 0;
  font-family: gilroy-bold;
  color: #ffffff;
  width: 100%;
  resize: none;
  scrollbar-width: none;
}
.createModal .modalBody {
  font-family: Gilroy-light;
  font-size: clamp(0.9rem, 3vw, 1.5rem);
  margin-top: 1.5rem;
  scrollbar-width: thin;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes fade-out {
  0% {
    transform: scaleY(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}
html {
  scrollbar-width: thin;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: none;
  flex-direction: none;
  gap: none;
  min-height: 100vh;
  position: relative;
  font-family: Gilroy-light;
  background-color: #ffffff;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='20' height='20' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 255, 255,1)'/%3E%3Ccircle cx='40' cy='20' r='0.25' fill='rgba(26, 32, 44,1)'/%3E%3Ccircle cx='0' cy='20' r='1' fill='rgba(255, 119, 61,1)'/%3E%3Ccircle cx='40' cy='20' r='1' fill='rgba(255, 119, 61,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
}
body ::selection {
  background-color: #ff773d;
  color: #dbdbdb;
}
body main {
  color: #dbdbdb;
  text-align: center;
  margin-bottom: 2em;
  width: 90vw;
}
body main .note-container {
  display: grid;
  grid-gap: 1.5em;
  word-break: break-all;
  position: relative;
}
body main .note-container .note-reminder {
  transform: translateY(20vh);
  font-size: clamp(0.9rem, 3vw, 1.5rem);
  display: none;
  position: absolute;
  width: 100%;
}
body main .note-container .listItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: none;
  flex-direction: column-reverse;
  gap: 0.5rem;
  padding: 2em 2em;
  border-radius: 10px;
  color: #333333;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.199);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.199);
  z-index: 1;
  position: relative;
  word-break: break-word;
}
body main .note-container .listItem .listItemContainer {
  max-width: 90ch;
  text-align: start;
}
body main .note-container .listItem .listItemContainer .listItemContainer-title {
  font-family: Gilroy-light;
  font-weight: bold;
  font-size: clamp(1.3rem, 3vw, 2rem);
  margin-bottom: 1.625rem;
}
body main .note-container .listItem .listItemContainer .listItemContainer-message {
  font-size: 1rem;
  line-height: 1.7;
  color: #646464;
}

.titleContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: none;
  flex-direction: none;
  gap: none;
  margin-block: 2em;
}
.titleContainer .searchInput {
  padding-inline: 1.5rem;
  font-family: Gilroy-light;
  border-radius: 10px;
  border: none;
  background: #ececec;
}
.titleContainer .searchInput:focus {
  outline: 2px solid #ff773d;
}
.titleContainer .searchInput::-webkit-input-placeholder {
  color: rgba(112, 112, 112, 0.466);
}
.titleContainer h1 {
  color: #333333;
  font-size: clamp(2rem, 5vw, 3rem);
  font-family: gilroy-bold;
  position: relative;
}
.titleContainer h1 span {
  color: #ff773d;
}

footer {
  position: absolute;
  bottom: 0.5rem;
}
footer h1 {
  font-family: Gilroy-light;
  font-size: clamp(0.4rem, 3vw, 0.9rem);
  opacity: 0.5;
}

