@use "./colors" as *;
@use "./fonts" as *;

.listItemContainer-button {
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  position: relative;

  .listItemMenu {
    cursor: pointer;
  }

  .sidebarMenu {
    background: $primaryClr;
    border: 1px solid $secondaryClr;
    position: absolute;
    right: 0;
    visibility: hidden;
    top: 0;
    transition: 0.3s ease;
    opacity: 0;

    display: grid;
    place-items: center;
    transform: translateY(-0.9rem);
    width: 9em;

    button {
      padding: 1em 3em;
      width: 100%;
      cursor: pointer;
      font-family: $fontLight;
      border: none;
      background-color: transparent;

      &:hover {
        background-color: #f3f3f3;
        color: $accentClr;
      }
    }
  }

  & > * {
    background-color: transparent;
    border: none;

    &:focus + ul.sidebarMenu {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.enterBtn {
  width: 4rem;
  height: 4rem;
  background-color: $accentClr;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  z-index: 9999;

  i {
    font-size: 1.5rem;
    color: $primaryClr;
    transition: 0.3s ease;
  }

  &:hover {
    filter: brightness(1.1);

    & i {
      font-size: 2rem;
    }
  }
}

.createBtn {
  border: none;
  color: $secondaryClr;
  padding: 1rem 1.5rem;
  border-radius: 30px;
  font-family: $fontBold;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 1.5rem;
  float: right;
  border: 1px solid $secondaryClr;
  transition: all 0.3s ease;

  &:disabled:hover {
    box-shadow: none;
    transform: translateY(0);
  }
  &:hover {
    box-shadow: 0px 4px 0px 0px #33333345;
    transform: translateY(-4px);
  }

  &:focus {
    transform: translateY(0);
    box-shadow: 0px 0px 0px 0px #33333345;
  }
}

.closeBtn {
  border: none;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.514);
  font-size: 1rem;
  font-family: $fontLight;
  margin-bottom: 1rem;
  cursor: pointer;
  float: right;
}
