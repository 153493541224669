html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-input-placeholder {
  color: #fff7;
}

textarea {
  outline: none;
}

@font-face {
  font-family: Gilroy-light;
  src: url("Gilroy-Light.9d6c3395.otf");
}

@font-face {
  font-family: gilroy-bold;
  src: url("Gilroy-ExtraBold.93f97143.otf");
}

.listItemContainer-button {
  -ms-grid-row-align: end;
  align-self: end;
  position: relative;
}

.listItemContainer-button .listItemMenu {
  cursor: pointer;
}

.listItemContainer-button .sidebarMenu {
  visibility: hidden;
  opacity: 0;
  width: 9em;
  background: #fff;
  border: 1px solid #dbdbdb;
  place-items: center;
  transition: all .3s;
  display: grid;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-.9rem);
}

.listItemContainer-button .sidebarMenu button {
  width: 100%;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 1em 3em;
  font-family: Gilroy-light;
}

.listItemContainer-button .sidebarMenu button:hover {
  color: #ff773d;
  background-color: #f3f3f3;
}

.listItemContainer-button > * {
  background-color: #0000;
  border: none;
}

.listItemContainer-button > :focus + ul.sidebarMenu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.enterBtn {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  z-index: 9999;
  background-color: #ff773d;
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 5rem;
  right: 5rem;
}

.enterBtn i {
  color: #fff;
  font-size: 1.5rem;
  transition: all .3s;
}

.enterBtn:hover {
  filter: brightness(1.1);
}

.enterBtn:hover i {
  font-size: 2rem;
}

.createBtn {
  color: #dbdbdb;
  cursor: pointer;
  float: right;
  border: 1px solid #dbdbdb;
  border-radius: 30px;
  margin-top: 1.5rem;
  padding: 1rem 1.5rem;
  font-family: gilroy-bold;
  font-size: .8rem;
  transition: all .3s;
}

.createBtn:disabled:hover {
  box-shadow: none;
  transform: translateY(0);
}

.createBtn:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px #33333345;
}

.createBtn:focus {
  transform: translateY(0);
  box-shadow: 0 0 #33333345;
}

.closeBtn {
  color: #ffffff83;
  cursor: pointer;
  float: right;
  background-color: #0000;
  border: none;
  margin-bottom: 1rem;
  font-family: Gilroy-light;
  font-size: 1rem;
}

@media (max-width: calc(30em - .001px)) {
  .titleContainer > div {
    justify-content: none;
    align-items: none;
    gap: none;
    flex-direction: column;
    display: flex;
  }
}

@media (max-width: 799.999px) {
  .titleContainer {
    justify-content: none;
    align-items: none;
    flex-direction: column;
    gap: 1.5rem;
    display: flex;
  }

  .titleContainer .searchInput {
    width: 100%;
    padding: 1rem;
  }

  .enterBtn {
    bottom: 2rem;
    right: 2rem;
  }
}

@media (min-width: 390.001px) {
  .note-container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

.createModal {
  word-break: break-all;
  color: #fff;
  background-color: #ff773d;
  border: none;
  margin: auto;
  padding: max(2rem, 2vw);
  position: relative;
}

.createModal::-webkit-backdrop {
  -webkit-backdrop-filter: blur();
  -webkit-backdrop-filter: blur();
  backdrop-filter: blur();
}

.createModal::backdrop {
  -webkit-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.createModal ::selection {
  color: #ff773d;
  background-color: #dbdbdb;
}

.createModal .modalTitle, .createModal .modalBody {
  color: #fff;
  width: 100%;
  resize: none;
  scrollbar-width: none;
  background-color: #0000;
  border: 0;
  padding-bottom: .5rem;
  font-family: gilroy-bold;
  font-size: clamp(1.3rem, 4vw, 2.5rem);
}

.createModal .modalBody {
  scrollbar-width: thin;
  margin-top: 1.5rem;
  font-family: Gilroy-light;
  font-size: clamp(.9rem, 3vw, 1.5rem);
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}

html {
  scrollbar-width: thin;
}

body {
  justify-content: center;
  align-items: none;
  flex-direction: none;
  gap: none;
  min-height: 100vh;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='20' height='20' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 255, 255,1)'/%3E%3Ccircle cx='40' cy='20' r='0.25' fill='rgba(26, 32, 44,1)'/%3E%3Ccircle cx='0' cy='20' r='1' fill='rgba(255, 119, 61,1)'/%3E%3Ccircle cx='40' cy='20' r='1' fill='rgba(255, 119, 61,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
  font-family: Gilroy-light;
  display: flex;
  position: relative;
}

body ::selection {
  color: #dbdbdb;
  background-color: #ff773d;
}

body main {
  color: #dbdbdb;
  text-align: center;
  width: 90vw;
  margin-bottom: 2em;
}

body main .note-container {
  grid-gap: 1.5em;
  word-break: break-all;
  display: grid;
  position: relative;
}

body main .note-container .note-reminder {
  width: 100%;
  font-size: clamp(.9rem, 3vw, 1.5rem);
  display: none;
  position: absolute;
  transform: translateY(20vh);
}

body main .note-container .listItem {
  justify-content: flex-end;
  align-items: none;
  color: #333;
  z-index: 1;
  word-break: break-word;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column-reverse;
  gap: .5rem;
  padding: 2em;
  display: flex;
  position: relative;
  box-shadow: 0 10px 20px #0003;
}

body main .note-container .listItem .listItemContainer {
  max-width: 90ch;
  text-align: start;
}

body main .note-container .listItem .listItemContainer .listItemContainer-title {
  margin-bottom: 1.625rem;
  font-family: Gilroy-light;
  font-size: clamp(1.3rem, 3vw, 2rem);
  font-weight: bold;
}

body main .note-container .listItem .listItemContainer .listItemContainer-message {
  color: #646464;
  font-size: 1rem;
  line-height: 1.7;
}

.titleContainer {
  justify-content: space-between;
  align-items: none;
  flex-direction: none;
  gap: none;
  margin-block: 2em;
  display: flex;
}

.titleContainer .searchInput {
  background: #ececec;
  border: none;
  border-radius: 10px;
  padding-inline: 1.5rem;
  font-family: Gilroy-light;
}

.titleContainer .searchInput:focus {
  outline: 2px solid #ff773d;
}

.titleContainer .searchInput::-webkit-input-placeholder {
  color: #70707077;
}

.titleContainer h1 {
  color: #333;
  font-family: gilroy-bold;
  font-size: clamp(2rem, 5vw, 3rem);
  position: relative;
}

.titleContainer h1 span {
  color: #ff773d;
}

footer {
  position: absolute;
  bottom: .5rem;
}

footer h1 {
  opacity: .5;
  font-family: Gilroy-light;
  font-size: clamp(.4rem, 3vw, .9rem);
}

/*# sourceMappingURL=index.435fc04c.css.map */
