@use "./fonts" as *;
@use "./colors" as *;

.createModal {
  background-color: $accentClr;
  border: none;
  padding: max(2rem, 2vw);
  margin: auto;
  word-break: break-all;
  color: white;
  position: relative;

  &::-webkit-backdrop {
    -webkit-backdrop-filter: blur(0);
    backdrop-filter: blur(0);
  }

  &::backdrop {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  ::selection {
    background-color: $secondaryClr;
    color: $accentClr;
  }

  .modalTitle {
    font-size: clamp(1.3rem, 4vw, 2.5rem);
    background-color: transparent;
    padding-bottom: 0.5rem;
    border: 0;
    font-family: $fontBold;
    color: $primaryClr;
    width: 100%;
    resize: none;
    scrollbar-width: none;
  }

  .modalBody {
    @extend .modalTitle;
    font-family: $fontLight;
    font-size: clamp(0.9rem, 3vw, 1.5rem);
    margin-top: 1.5rem;

    scrollbar-width: thin;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes fade-out {
  0% {
    transform: scaleY(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}
