@forward "./reset";
@forward "./butttons";
@forward "./mediaqueries";
@forward "./cards";
@use "./colors" as *;
@use "./mixins" as *;
@use "./fonts" as *;

html {
  scrollbar-width: thin;
}

body {
  @include flexProp(center, none, none, none);
  min-height: 100vh;
  position: relative;
  font-family: $fontLight;
  background-color: $primaryClr;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='20' height='20' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 255, 255,1)'/%3E%3Ccircle cx='40' cy='20' r='0.25' fill='rgba(26, 32, 44,1)'/%3E%3Ccircle cx='0' cy='20' r='1' fill='rgba(255, 119, 61,1)'/%3E%3Ccircle cx='40' cy='20' r='1' fill='rgba(255, 119, 61,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");

  ::selection {
    background-color: $accentClr;
    color: $secondaryClr;
  }

  main {
    color: $secondaryClr;
    text-align: center;
    margin-bottom: 2em;
    width: 90vw;

    .note-container {
      display: grid;
      grid-gap: 1.5em;
      word-break: break-all;
      position: relative;

      .note-reminder {
        transform: translateY(20vh);
        font-size: clamp(0.9rem, 3vw, 1.5rem);
        display: none;
        position: absolute;
        width: 100%;
      }

      .listItem {
        @include flexProp(flex-end, none, column-reverse, 0.5rem);
        padding: 2em 2em;
        border-radius: 10px;
        color: $textClr;
        background-color: $primaryClr;
        -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.199);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.199);
        z-index: 1;
        position: relative;
        word-break: break-word;

        .listItemContainer {
          max-width: 90ch;
          text-align: start;

          .listItemContainer-title {
            font-family: $fontLight;
            font-weight: bold;
            font-size: clamp(1.3rem, 3vw, 2rem);
            margin-bottom: 1.625rem;
          }

          .listItemContainer-message {
            font-size: 1rem;
            line-height: 1.7;
            color: $fadeClr;
          }
        }
      }
    }
  }
}

.titleContainer {
  @include flexProp(space-between, none, none, none);
  margin-block: 2em;

  .searchInput {
    padding-inline: 1.5rem;
    font-family: $fontLight;
    border-radius: 10px;
    border: none;
    background: #ececec;

    &:focus {
      outline: 2px solid $accentClr;
    }

    &::-webkit-input-placeholder {
      color: rgba(112, 112, 112, 0.466);
    }
  }

  h1 {
    color: $textClr;
    font-size: clamp(2rem, 5vw, 3rem);
    font-family: $fontBold;
    position: relative;

    span {
      color: $accentClr;
    }
  }
}

footer {
  position: absolute;
  bottom: 0.5rem;

  h1 {
    font-family: $fontLight;
    font-size: clamp(0.4rem, 3vw, 0.9rem);
    opacity: 0.5;
  }
}
